<template>
  <div class="avatar">
    <div class="dark"></div>
    <div class="content_box">
      <router-view :key="$route.path"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Avatar",
};
</script>

<style scoped>
.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
  flex-direction: column;

  padding: 2rem 0;

  overflow-y: auto;

  background-color: #000;
  background-size: auto 100%;
  background-image: url("../../assets/img/img_login_background.jpg");
  background-repeat: repeat;
  background-attachment: fixed;
  animation: 120s linear 0s infinite normal none running avatar_animation;
}
@keyframes avatar_animation {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: calc(-100vw + 100%) 0px;
  }
}
.avatar > .dark {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 0;
}
.avatar > .content_box {
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgb(238, 238, 238);

  width: 640px;
  max-width: 100%;
  padding: 1.4rem;
  z-index: 1;
}

@media (max-width: 600px) {
  .avatar {
    padding: 0;
  }
  .avatar > .content_box {
    flex-grow: 1;
    border-radius: 0;
    width: 100%;
    min-height: 100%;
  }
}
</style>
